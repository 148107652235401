import { Collectible } from "@/types/collectibles"
import { BaseUserInternal, CryptoUser } from "@/types/users"
import { BlogRecommendation, Note } from "@types"

/**
 * A meta tag object.
 */
export interface MetaTag {
  property: string
  content: string
}

/**
 * The possible responses that a frame can generate.
 */
export enum RESPONSES {
  /**
   * The blog homepage frame response.
   */
  HOMEPAGE_BLOG = "HOMEPAGE_BLOG",

  /**
   * The post homepage frame response (the first page a user sees, and that they can go "back" to).
   */
  HOMEPAGE_POST = "HOMEPAGE_POST",

  /**
   * The current page of an inline read frame response.
   * This is the current page of the post, and the user can click "back" or "next" to navigate.
   * Even if it's the first page, the back button will still be there to let them navigate back
   * to the HOMEPAGE.
   */
  READ_INLINE_PAGE = "READ_INLINE_PAGE",

  /**
   * The last page of an inline read frame response.
   * This is the last page of the post, and the user can click "back" to go back to the previous page.
   */
  READ_INLINE_LAST_PAGE = "READ_INLINE_LAST_PAGE",

  /**
   * The frame response for the "add email" frame.
   * This is the frame that asks the user to add their email to subscribe to the blog.
   */
  ADD_EMAIL = "ADD_EMAIL",

  /**
   * The frame response for the "recommendations" frame.
   * This is the frame that shows recommended publications to the user.
   */
  RECOMMENDATIONS = "RECOMMENDATIONS",

  /**
   * The frame response for the "mint buttons" frame.
   * This is the frame that shows the mint buttons to the user.
   */
  MINT_BUTTONS = "MINT_BUTTONS",

  /**
   * One of several success frame responses.
   * This is the frame that shows a success message to the user if they've successfully minted a post.
   */
  SUCCESS_MINT = "SUCCESS_MINT",

  /**
   * One of several success frame responses.
   * This is the frame that shows a success message to the user if they've successfully subscribed to a blog.
   */
  SUCCESS_SUBSCRIBED = "SUCCESS_SUBSCRIBED",

  /**
   * When a user subscribes, this thanks them and prompts them to also share the blog on farcaster.
   */
  SHARE_FARCASTER_AFTER_SUBSCRIBE = "SHARE_FARCASTER_AFTER_SUBSCRIBE",

  /**
   * The error frame response.
   */
  ERROR = "ERROR",
}

export type HomepageBlogResponse = {
  type: RESPONSES.HOMEPAGE_BLOG

  /**
   * Image shown to the user in the frame.
   */
  image: string
}

export type HomepagePostResponse = {
  type: RESPONSES.HOMEPAGE_POST

  /**
   * Image shown to the user in the frame.
   */
  image: string

  /**
   * Whether the post is post-level gated. If true, the "read inline" button will not be displayed.
   */
  // isPostLevelGate: boolean
}

export type ReadInlinePageResponse = {
  type: RESPONSES.READ_INLINE_PAGE

  /**
   * Image shown to the user in the frame.
   */
  image: string

  /**
   * Whether we should deny the user access to the post. This is true if either:
   *                   1. The post is post-level gated AND the user doesn't have access.
   *                   2. The post is after-level gated AND the user doesn't have access
   *                      AND they're trying to see a page after the gate.
   */
  denyAccess: boolean

  /**
   * The current page of the post.
   */
  currentPage: number

  /**
   * The total number of pages in the post.
   */
  totalPageCount: number
}

export type ReadInlineLastPageResponse = {
  type: RESPONSES.READ_INLINE_LAST_PAGE

  /**
   * Image shown to the user in the frame.
   */
  image: string

  /**
   * The current page of the post.
   */
  currentPage: number

  /**
   * The total number of pages in the post.
   */
  totalPageCount: number
}

export type AddEmailResponse = {
  type: RESPONSES.ADD_EMAIL

  /**
   * User object for the blog owner. Used as a fallback if the blog doesn't have a logo.
   */
  blogOwnerUser: FrameUser
}

export type RecommendationsResponse = {
  type: RESPONSES.RECOMMENDATIONS

  /**
   * Publications recommended by this blog.
   * The recommendations page is only generated when a reader clicks the "Subscribe"
   * button on either the blog or post frame.
   */
  blogRecommendations: BlogRecommendation[]
}

export type MintResponse = {
  type: RESPONSES.MINT_BUTTONS

  /**
   * Image shown to the user in the frame.
   */
  image: string

  /**
   * The collectible that the user is minting.
   */
  collectible: Collectible
}

export type SuccessMintResponse = {
  type: RESPONSES.SUCCESS_MINT

  /**
   * User object for the blog owner.
   */
  blogOwnerUser?: FrameUser
}

export type SuccessSubscribedResponse = {
  type: RESPONSES.SUCCESS_SUBSCRIBED

  /**
   * User object for the blog owner.
   */
  blogOwnerUser?: FrameUser
}

export type ShareFarcasterAfterSubscribeResponse = {
  type: RESPONSES.SHARE_FARCASTER_AFTER_SUBSCRIBE

  /**
   * User object for the blog owner.
   */
  blogOwnerUser?: FrameUser

  /**
   * Text shown to the user to explain what they're doing, e.g. thanking them and prompting them to share.
   */
  description: string
  /**
   * This is the eth address of the new caster, for referral rewards.
   */
  casterEthAddr: string
  /**
   * This is the FC username of the author of a post.
   *
   * We tag them when users share.
   */
  authorFarcasterUsername?: string
}

export type ErrorResponse = {
  type: RESPONSES.ERROR

  /**
   * The error message to show to the user. If you don't know, just choose UNKNOWN.
   */
  errorMsg: ERROR

  /**
   * User object for the blog owner.
   */
  blogOwnerUser?: FrameUser
}

/*****--------------------------*****/
/*****   FRAME ERROR MESSAGES   *****/
/*****--------------------------*****/

/**
 * The possible error messages that a frame can generate.
 */
export enum ERROR {
  UNKNOWN = "There was an error processing your request.",
  FARCASTER_MISSING_WALLET_ADDRESS = "You don't have an ETH address connected to your Farcaster account. Connect one in your FC settings first.",
  FARCASTER_MINT_NOT_SUPPORTED = "We don't support minting from XMTP yet",
  SUBSCRIBING = "There was an error subscribing to the blog.",
  SUBSCRIBING_INVALID_EMAIL = "Please enter a valid email address.",
  EMAIL_TAKEN = "That email belongs to another account. Login to paragraph.xyz to connect it to your wallet.",
}

/**
 * The possible responses that a frame can generate.
 * It includes any additional fields that some responses may require you to pass in.
 */
export type FrameResponseTypes =
  | HomepageBlogResponse
  | HomepagePostResponse
  | ReadInlinePageResponse
  | ReadInlineLastPageResponse
  | AddEmailResponse
  | RecommendationsResponse
  | MintResponse
  | SuccessMintResponse
  | SuccessSubscribedResponse
  | ShareFarcasterAfterSubscribeResponse
  | ErrorResponse

/**
 * Make sure this contains all the fields we use in the FrameResponseGenerator class.
 */
export type FrameUser = Pick<
  BaseUserInternal & Partial<CryptoUser>,
  "id" | "avatar_url" | "referrerWalletAddress" | "wallet_address"
>

/**
 * This is a reduced set of fields from the more complete FramePostWithJson
 * Note type that we use in the FrameResponseGenerator class when generating
 * inline reading frames.
 *
 * Note: These need to match the fields retrieved by the middleware in /api/middleware/helpers.ts
 */
export type FramePost = Pick<
  Note,
  | "id"
  | "slug"
  | "title"
  | "subtitle"
  | "draftOf"
  | "cover_img"
  | "cover_img_url"
  | "collectibleImgUrl"
  | "collectiblesDisabled"
  | "highlightsChain"
  | "highlightsCost"
  | "highlightsSupply"
  | "collectibleWalletAddress"
>

/**
 * This is a reduced set of fields from the more complete Note.
 * It contains only the fields that we use in the FrameResponseGenerator class,
 * and especially the heavy `json` field used for generating inline reading frames.
 */
export type FramePostWithJson = FramePost & Pick<Note, "json">

export type FramePostFields = keyof FramePost | keyof FramePostWithJson
