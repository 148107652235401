import { Blog } from "@types"
import NextHead from "next/head"

interface Props {
  children?: Array<JSX.Element | undefined | null> | JSX.Element
  blog?: Blog

  title?: string
  share_title?: string
  description?: string
  share_img?: string
  canonicalUrl?: string
  creator?: string

  // The canonical URL of the page, used for the og:url meta tag
  fullUrl?: string

  type?: string // The type of page, used for the og:type meta tag
}

export default function Head(props: Props) {
  const description =
    props.description ||
    props.blog?.summary ||
    props.blog?.about ||
    "Create, publish and share onchain blogs & newsletters."

  const title =
    props.title ||
    props.blog?.name ||
    "Paragraph | all-in-one publishing & newsletter platform"

  const share_title = props.share_title || title

  const creator = props.creator || props.blog?.social?.twitter

  const image =
    props.share_img ||
    props.blog?.logo_url ||
    props.blog?.user?.avatar_url ||
    "https://paragraph.xyz/share/share_img.jpg"

  // @caelmux requested we remove the favicon
  const BLOCKLISTED_FAVICON_BLOGIDS = ["CNk8lnIMoVN26yDBxsXQ"]

  const hasCustomFavicon =
    props.blog &&
    props.blog?.logo_url &&
    !BLOCKLISTED_FAVICON_BLOGIDS.includes(props.blog?.id)

  return (
    <NextHead>
      {hasCustomFavicon && <link rel="icon" href={props.blog?.logo_url} />}

      {!hasCustomFavicon && (
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
      )}
      {!hasCustomFavicon && (
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
      )}
      {!hasCustomFavicon && (
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
      )}

      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
      <meta name="msapplication-TileColor" content="#da532c" />
      <meta name="theme-color" content="#ffffff" />

      {/* If the user is banned or suspicious, don't index */}
      {props.blog?.needToSetup === true ||
        (["LOW", "VERY_LOW"].includes(props.blog?.reputation || "") && (
          <meta name="robots" content="noindex" />
        ))}

      <title>{title}</title>
      <meta name="description" content={description} />

      {props.canonicalUrl && <link rel="canonical" href={props.canonicalUrl} />}

      {/* If the user has an explicit share image, eg a cover photo,
          display it large. Otherwise, display a small snippet of
          the blogs profile / author image. */}
      <meta
        name="twitter:card"
        content={props.share_img ? "summary_large_image" : "summary"}
      />
      <meta name="twitter:title" content={share_title} />
      <meta name="twitter:site" content="@paragraph_xyz" />
      <meta name="twitter:description" content={description} />
      {creator && <meta name="twitter:creator" content={`@${creator}`} />}

      <meta property="og:type" content={props.type || "article"} />
      <meta property="og:description" content={description} />
      <meta property="og:title" content={share_title} />

      {props.fullUrl && (
        <>
          <meta property="og:url" content={props.fullUrl} />
          <link rel="canonical" href={props.fullUrl} />
        </>
      )}

      {image && (
        <>
          <meta property="og:image" content={image} />
          <meta name="twitter:image" content={image} />

          <meta name="twitter:image:src" content={image} />
        </>
      )}

      {props.children}
    </NextHead>
  )
}
